<template>
  <div class="notfound-container">
    <!-- <div> -->
    <img class="notfound-image" src="../assets/no.png" alt="notfound" />
    <div class="notfound-title">
      Bạn không thuộc đối tượng được quyết toán thuế do không phát sinh thu nhập
      trong năm 2021
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.notfound-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background: #E5E5E5;
  height: 100%;
  .notfound-image {
    margin-top: 50px;
  }
  .notfound-title {
    margin-top: 20px;
    width: 80%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7a8189;
    text-align: center;
  }
}
</style>